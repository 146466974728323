import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import SEO from '../components/seo';
// images
import WhyImg1 from '../images/why1.jpg';
import WhyImg2 from '../images/why2.jpg';
import '../styles/app.scss';

const schedule = ({ data }) => {
    const { wordpressPage: post } = data;
    console.log(post);
    const test_title = post.yoast_title;
    const new_test_title = test_title.replace('&#039;', "'");
    const inlineCss = {
        maxWidth: '600px',
        marginTop: '50px',
    };

    console.log(post.yoast_json_ld[0].wordpress__graph[0].description);

    return (
        <>
            <SEO
                title={new_test_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container display_none">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />

                    
                    {/* <a
                        className="wrtsbtn yellowbtn healcode-register"
                        style={inlineCss}
                        href="https://clients.mindbodyonline.com/ASP/su1.asp?studioid=916083"
                        target="_blank"
                    >
                        To sign up for our upcoming Classes/Special Events -
                        Click Here
                    </a> */}
                </div>
				
				{/* <iframe
                    src="https://werockthespectrumatlantagym.wrtsfranchise.com/schedule"
                            className="googlecal"
                        /> */}
                <div className=""
                    dangerouslySetInnerHTML={{
                        __html: `<div class="elfsight-app-2acf6a71-a7dd-4ee2-bd06-c122485d6e65" data-elfsight-app-lazy></div>`,
                    }}
                />
                
                <iframe className="googlecal" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23fceb1f&ctz=America%2FNew_York&title=We%20Rock%20The%20Spectrum%20Roswell-Atlanta&showPrint=0&mode=WEEK&src=N2F1NWEwMzAxdmNoM2o2bTIxMm1obnI1bzhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=MzAwNjkyZDNiYjllZTIyMGFjMTY3OTVmMGIwMWI4MGRiMmFjYTM5MmJiYzA2Zjc4YzllZjY5ODA5ODE4ODQ4YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=OTNhZmI2Nzg4ZmFjMmMxYzhjZmE1NWM5NmI1MTIyMGI0ZjliODUzMTliYTE0N2ZhYTI4ODllMDRmMmRhM2E1YUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NDZlYmNidGoyYTRqanFpZ2U1ZXNtdXRwbWtAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=YzFiMDNlNTE0ZTgxZDQ4MmYyZWFjNGRlYWY1MmFlMDM4NzRkODFiZmQzMWNjYjllOThmNWU4MDQ1MDg1ODllNUBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23D50000&color=%23039BE5&color=%23D81B60&color=%23EF6C00&color=%237CB342"></iframe>

                {/* <div className="oplayhealwrap">
                    <h5 class="bluetxt">Book online</h5>
                    <div
                        className=""
                        dangerouslySetInnerHTML={{
                            __html: post.acf.schedule_iframe_src,
                        }}
                    />
                </div> */}
                
            </section>           
            <Footer />
        </>
    );
};

export default schedule;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                schedule_iframe_src
                story_time_title
                story_time_content
                story_time_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                music_classes_title
                music_classes_content
                music_classes_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
